































import { defineComponent } from "@vue/composition-api";
import router from "@/router";
import { ERoutes } from "@/core/dataTypes/enum";
import { getLanguageStaticRoute } from "@/core/utils/router.util";
import { i18n } from "@/locales/i18n";

export default defineComponent({
  name: "ForgottenPasswordEmailSentPage",
  setup() {
    const navigatePurchase = () => {
      router.push(getLanguageStaticRoute(ERoutes.STICKER_PURCHASE));
    };
    return {
      navigatePurchase,
    };
  },
  metaInfo() {
    return {
      titleTemplate: `${i18n.tc("EMPTY_CART_PAGE.PAGE_TITLE")} | %s`,
    };
  },
});
